.footer {
  display: flex;
  padding: 44px 68px;
  flex-direction: column;
  align-items: center;
  gap: 80px;
  align-self: stretch;
  background: #2d2d2d;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
  gap:40px;
}

.page-links {
  display: flex;
  gap: 32px;
}

.page-links > .paragraph1 {
  color: white;
  cursor: pointer;
}

.page-links > .paragraph1:hover {
  text-decoration: underline;
}
.social-links {
  display: flex;
  align-items: center;
  gap: 12px;
}

.social-links > div {
  cursor: pointer;
}

.footer-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  background-color: black;
}

.hr {
  height: 1px;
  align-self: stretch;
  background: var(--Color-white, #fff);
}
.footer-static-page-links {
  display: flex;
  gap: 24px;
}
.paragraph2 {
  color: var(--color-black, #ffffff);
  font-family: "Chakra Petch";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.footer-static-page-links > .paragraph1 {
  color: white;
}
.footer-static-page-links > a {
  text-decoration-line: underline;
  cursor: pointer;
}
.footer-sections {
  display: flex;
  gap: 80px;
  text-align: start;
  flex-wrap: wrap;
}
.footer-redirect-header {
  font-size: 18px;
  font-weight: 500;
}
.footer-column {
  width: 180px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.footer-redirect {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.footer-section2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000; /* Black background */
  padding: 20px 68px;
  color: #fff; /* White text */
  font-family: "Arial", sans-serif;
  font-size: 14px;
  border-top: 1px solid #444; /* Optional divider */
}

.footer-social-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.social-link {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}

.social-link:hover {
  color: #e6f4ff; /* Light blue hover effect */
}

.footer-info {
  text-align: center;
  display: flex;
  gap: 24px;
}

.footer-legal-links {
  display: flex;
  gap: 24px;
}

.legal-link {
  color: #fff;
  transition: color 0.3s;
  text-decoration: underline;
}

.legal-link:hover {
  color: #e6f4ff; /* Light blue hover effect */
}

@media (max-width: 768px) {
  .footer {
    padding: 40px;
  }
  .footer-main {
    flex-direction: column;
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .footer-static-page-links,
  .footer-sections,
  .page-links,
  .footer-section2,
  .footer-info,
  .footer-legal-links {
    flex-direction: column; 
  }

  .footer-section2,
  .footer-info,
  .footer-legal-links {
    align-items: flex-start;
  }

  .footer-section2 {
    padding: 20px 40px;
    gap:10px;
  }
}
