.works-imgs {
  display: grid;
  margin: 58px 0px;
  grid-template-columns: repeat(3, 1fr);
  gap: 58px;
}

.works-imgs img {
  width: 100%;
  /* height: 686px; */
  /* height: 386px; */
  object-fit: cover;
  border-radius: 16px;
}

@media (max-width: 768px) {
  .works-imgs {
    gap: 38px;
  }
}

@media (max-width: 480px) {
  .works-imgs {
    gap: 18px;
  }
}
