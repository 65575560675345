.header {
  position: sticky;
  top: 0px;
  background: #fefefe;
  z-index: 3;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}
.navbar {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  align-items: center;
}

.navs {
  display: flex;
  align-items: center;
  gap: 60px;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 40px;
  position: relative;
}

.nav-item {
  cursor: pointer;
}

.dropdown-container {
  display: flex;
  align-items: start;
  background-color: white;
  z-index: 4;
  flex-direction: column;
  position: absolute;
  padding: 24px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  gap: 10px;
  top: 40px;
  width: 50%;
}
.dropdown-item {
  display: flex;
  padding: 10px 0px;
  font-size: 16px;
  width: 100%;
  border-radius: 12px;
  padding-left: 0px;
  transition: padding-left 0.3s ease;
}
.dropdown-item:hover {
  background-color: #ebfaff;
  padding-left: 10px;
}

.active {
  /* text-decoration: underline; */
  border-bottom: 1px solid black;
  font-weight: 500;
}

.hamburger-menu {
  cursor: pointer;
  display: none;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background: var(
    --color,
    linear-gradient(91deg, #35c5ee 19.72%, #1e7188 79.08%)
  );
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}

.nav-menu-mobile {
  display: none;
}

.nav-items-mob {
  display: none;
}

@media (max-width: 900px) {
  .hamburger-menu {
    display: inline-block;
  }

  .mobile_contact_us_btn {
    display: none !important;
  }
  .nav-items {
    display: none;
  }
  .nav-items-mob {
    position: absolute;
    padding: 16px;
    left: 0;
    width: 100%;
    background: #ffff;
    bottom: 0;
    transform: translateY(100%);
    display: flex;
    gap: 30px;
    flex-direction: column;
    align-items: flex-start;
  }
  .navbar > .text-capsules {
    display: none;
  }

  .navbar-mobile {
    /* flex-direction: column; */
    box-shadow: 0 1px 10px #999;
  }
  .navs {
    /* width: 100%; */
    justify-content: space-between;
  }
  .active {
    border-bottom: none;
    font-weight: 500;
    width: 100%;
    background-color:#EBFAFF;
    border-radius: 6px;
  }
  .dropdown-container {
    display: flex;
    align-items: start;
    background-color: white;
    position: static;
    flex-direction: column;
    padding: 24px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    gap: 10px;
    width: 100%;
  }
  .dropdown-item {
    display: flex;
    padding: 10px 0px;
    font-size: 16px;
    width: 100%;
    border-radius: 12px;
    padding-left: 0px;
    transition: padding-left 0.3s ease;
  }
  .dropdown-item:hover {
    background-color: #ebfaff;
    padding-left: 10px;
  }
}
