.cards-headv2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 60px;
    background-color: #F9F9F9;
}

.cardsv2 {
    display: flex;
    gap: 28px;
    text-align: left;
    flex-wrap: wrap;
    justify-content: center;
}

.cardv2 {
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    padding: 20px 30px;
    flex: 1 1 calc(33% - 28px);
    border: 1px solid #DDE1E6;
    max-width: calc(33% - 28px);
}

.numberv2 {
    font-size: 24px;
    color: #B3B3B3;
    line-height: 31.2px;
    font-weight: 600;
}

.headingv2 {
    font-size: 28px;
    color: #0B0B0B;
    line-height: 35.11px;
    font-weight: 500;
    text-align: center;
}

.cardv2>.paragraph1v2 {
    text-align: center;
}

.imgv2 {
    background: linear-gradient(90deg, rgba(53, 197, 238, 1) 0%, rgba(30, 113, 136, 1) 100%);
    border-radius: 8px;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

@media (max-width: 768px) {
    .cardv2 {
        flex: 1 1 calc(50% - 28px);
        max-width: calc(50% - 28px);
    }
}

@media (max-width: 480px) {
    .cardv2 {
        flex: 1 1 100%;
        max-width: 100%;
    }
}