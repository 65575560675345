/* .about > .flex-container {
  flex-direction: row-reverse;
} */

.history {
  background: var(--Footer, #2d2d2d);
  padding: 112px 68px;
  /* margin-left: -68px;
  margin-right: -68px; */
}

.history * {
  color: white;
  border-color: white;
}

.core-values {
  text-align: left;
  background-color: #f9f9f9;
}

.core-values > .heading1 {
  max-width: 679px;
  text-align: left;
}

.core-value-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 58px;
  column-gap: 24px;
}

.core-value-grid-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  text-align: center;
  background-color: #ffffff;
  padding: 44px 36px;
  border-radius: 16px;
  border: 1px solid #dde1e6;
}

.core-value-grid-item > .heading2 {
  margin-top: 20px;
}

.core-value-grid-item > .paragraph1 {
  margin-top: 10px;
}

.grid-icon {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 8px;
  background: var(
    --color,
    linear-gradient(91deg, #35c5ee 19.72%, #1e7188 79.08%)
  );
}

.grid-icon > img {
  width: 40px;
  height: 40px;
}

.whatWeDo {
  display: flex;
  justify-content: start;
  flex-direction: column;
  text-align: left;
  gap: 60px;
}

.whatWeDo-section1 {
  display: flex;
  justify-content: start;
  flex-direction: column;
  text-align: left;
}

.whatWeDo-section1 > .heading1 {
  max-width: 679px;
  text-align: left;
}

.whatWeDo-section2 {
  display: flex;
  justify-content: start;
  text-align: left;
  gap: 60px;
}

.whatWeDo-section2 > img {
  width: 52%;
  aspect-ratio: 8/5;
  object-fit: cover;
}

.whatWeDo-content {
  display: flex;
  flex-direction: column;
  gap: 68px;
}

.whatWeDo-Para {
  display: flex;
  gap: 20px;
}

.whatWeDo-icon {
  width: 60px;
  height: 40px;
}

.whatWeDo-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.Workforce {
  display: flex;
  justify-content: start;
  flex-direction: column;
  text-align: left;
  gap: 60px;
}

.Workforce-section1 {
  display: flex;
  justify-content: start;
  flex-direction: column;
  text-align: left;
}

.Workforce-section1 > .heading1 {
  text-align: left;
}

.Workforce-section2 {
  display: flex;
  justify-content: start;
  text-align: left;
  gap: 60px;
}

.Workforce-section2 > img {
  width: 33%;
  object-fit: cover;
}

.Workforce-content {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 24px;
}

.Workforce-Para {
  display: flex;
  gap: 20px;
}

.Workforce-heading1 {
  color: var(--color-black, #0b0b0b);
  text-align: center;
  margin-top: 10px;

  /* Heading 1 */
  font-family: Orbitron;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.whatWeDo-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .flex-container {
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
} */

/* .about > .flex-container {
  @media (max-width: 1200px) {
    flex-direction: column-reverse;
  }
} */

@media (max-width: 768px) {
  .history {
    padding: 40px;
    /* margin-left: -25px;
    margin-right: -25px; */
  }

  .core-value-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .core-value-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .whatWeDo-section2,
  .Workforce-section2 {
    flex-direction: column;
  }
  .whatWeDo-section2 > img,
  .Workforce-section2 > img {
    width: 100%;
  }
}
