.text-capsules {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 32px;
  color: var(--Black, var(--color-black, #0b0b0b));
  border: 0.5px solid var(--color-black, #0b0b0b);
  width: fit-content;
}

.arrow-svg {
  width: 28px;
  height: 28px;
}

.flex-container {
  display: flex;
  align-items: center;
  gap: 60px;
  justify-content: space-between;
  white-space: pre-wrap;
  /* flex-direction: row-reverse;  home page exception  */
}

.flex-container-file {
  width: 50%;
}

.flex-container-file > video {
  /* max-width: 600px; */
  width: 100%;
  height: 472px;
  object-fit: cover;
  border-radius: 16px;
}
.flex-container-file > img {
  width: 100%;
  height: 388px;
  object-fit: cover;
  border-radius: 16px;
}

.flex-container-text {
  text-align: left;
  width: 50%;
}

.flex-container-text > .heading1 {
  text-align: left;
}

.flex-container-text > .paragraph1 {
  margin-top: 20px;
}
.flex-container-btn {
  margin-top: 30px;
}

.QuestionBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--color-black, #0b0b0b);
  background: var(--Color-white, #fff);
  padding: 23px 40px;
  cursor: pointer;
}

.QuestionBlock-que {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.QuestionBlock-sol {
  /* display: none; */
  width: 100%;
  text-align: left;
}

.text-capsules.clickable {
  cursor: pointer;
}

.text-capsules.clickable:hover {
  background: var(
    --color,
    linear-gradient(91deg, #35c5ee 19.72%, #1e7188 79.08%)
  ) !important;
}

.banner-img > img {
  height: 400px;
  width: 100%;
  align-self: stretch;
  border-radius: 16px;
  object-fit: cover;
}

.flex-container2-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 68px;
  align-self: stretch;
  margin-top: 68px;
}

.flex-container2-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 58px;
  text-align: left;
  width: 40%;
}

.flex-container2-grid-item {
  display: flex;
  gap: 20px;
}
.flex-container2-img {
  width: 60%;
}

.flex-container2-img > img {
  /* max-width: 756px; */
  /* height: 100%; */
  width: 100%;
  /* width: 756px; */
  height: 592px;
  /* height: 636px; */
  object-fit: cover;
  border-radius: 16px;
}

.flex-container-2 {
  text-align: -webkit-center;
}

.banner {
  text-align: -webkit-center;
}


.set-order .flex-container-text,
.set-order .flex-container2-grid {
  order: 1;
}

.bannerv2-head {
  padding: 40px 20px 0 20px; /* Adjust padding for smaller screens */
  background-size: cover;
  display: flex;
  justify-content: center;
}

.bannerv2 {
  position: relative;
  padding: 40px 0; /* Reduced padding for smaller screens */
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
}

.bannerv2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../assets/Screenshot\ 2024-12-24\ 093457.png');
  background-size: cover;
  border-radius: 16px;
  opacity: 0.4;
  z-index: 1;
}

.bannerv2-text {
  position: relative;
  width: 90%; /* Adjust width for smaller screens */
  max-width: 900px; /* Ensure it doesn't exceed the original width */
  text-align: center;
  z-index: 2;
  color: #000;
  font-size: 18px; /* Adjust font size for readability */
  line-height: 1.5;
}

@media (max-width: 768px) {
  .bannerv2-head {
    padding: 30px 15px 0 15px; /* Further reduced padding for medium screens */
  }

  .bannerv2 {
    padding: 30px 0;
  }

  .bannerv2-text {
    font-size: 16px; /* Slightly smaller font size */
    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .bannerv2-head {
    padding: 20px 10px 0 10px; /* Minimal padding for very small screens */
  }

  .bannerv2 {
    padding: 20px 0;
  }

  .bannerv2-text {
    font-size: 14px; /* Smaller font size for small devices */
    line-height: 1.3;
  }
}


@media (max-width: 786px) {
  .flex-container2-flex {
    flex-direction: column;
  }
  .set-order .flex-container-text,
  .set-order .flex-container2-grid {
    order: 0;
  }

  .flex-container2-img {
    width: 100%;
  }
  .flex-container2-grid {
    width: 100%;
  }
  .flex-container {
    flex-direction: column;
  }
  .flex-container-text {
    text-align: -webkit-center;
    width: 100%;
  }
  .flex-container-file {
    width: 100%;
  }

  .flex-container-text > .heading1 {
    text-align: center;
  }
}
