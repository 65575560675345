.our-location{
    display: flex;
    justify-content: start;
    flex-direction: column;
    text-align: left;
    gap: 60px;
}
.our-location-section1{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items:  start;
}
.Location-col{
    display: flex;
    flex: 1;
    gap:24px;
}

.Location-sec{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.Location-sec>img{
 width: 100%;
 border-radius: 16px;
}
.Location-details{
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.detail{
    display: flex;
    align-items: start;
    gap:12px;
}

.detail>svg{
    height: 25px;
    width: 25px;
    min-width: 25px;
}

@media (max-width: 480px) {
    .Location-col{
        flex-direction: column;
    }
 }


