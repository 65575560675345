.benefit {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.benefits-section-1 {
  text-align: left;
}
.benefits-section-1 > .heading1 {
  text-align: left;
}

.benefits-section-2 {
  width: 100%;
  display: flex;
  gap: 60px;
}
.benefits-section-2 > img {
  width: 30%;
  border-radius: 16px;
  aspect-ratio: 1.03;
  object-fit: cover;
}

.section-2-2 {
  border-radius: 10px;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap:60px;
  cursor: pointer;
  padding: 20px 30px;
  width: 70%;
}
.box {
  display: flex;
  gap: 10px;
  align-items: start;
  flex-direction: column;
}
.box-in {
  display: flex;
  gap: 10px;
  align-items: center;
  text-align: left;
  gap: 24px;
}
.text {
  padding-left: 44px;
  text-align: left;
}
.dot {
  border-radius: 50%;
  background: linear-gradient(
    90deg,
    rgba(53, 197, 238, 1) 0%,
    rgba(30, 113, 136, 1) 100%
  );
  width: 20px;
  height: 20px;
  min-width: 20px;
}
.headingv3 {
  font-size: 28px;
  color: #0B0B0B;
  line-height: 35.11px;
  font-weight: 500;
  text-align: start;
  
}

@media (max-width: 480px) {
  .benefits-section-2 {
    flex-direction: column;
  }

  .benefits-section-2 > img {
    width: 100%;
  }

  .section-2-2{
    padding: 0;
    grid-template-columns: 380px;
  }
}
