.contact-us {
  text-align: -webkit-center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex-shrink: 0;
}
.contact-us-form {
  display: flex;
  gap: 68px;
  margin-top: 68px;
  justify-content: center;
  width: 100%;
}

.input-panel {
  width: 50%;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

input,
textarea {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 0.5px solid var(--color-black, #0b0b0b);
  background: var(--Color-white, #fff);
  resize: none;
}

textarea {
  height: 180px;
}

.contact-deatils {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  align-self: stretch;
  text-align: left;
}
.contact-deatils .form-head1 {
  margin-top: 16px;
}
.contact-deatils .paragraph1 {
  margin-top: 8px;
}

.mail-to label {
  color: #0b0b0b9e;
}
.mail-to input {
  border-color: #0b0b0b9e;
}

.email-to {
  display: flex;
  padding: 16px 32px;
  align-items: flex-start;
  max-width: fit-content;
  align-items: center;
  margin-top: 68px;
  border-radius: 8px;
  background: var(--Color-white, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.contact-panel {
  text-align: left;
  width: 50%;
}

.contact-panel .heading1 {
  text-align: left;
  max-width: 553px;
}

.reset-button .text-capsules:hover {
  background: var(
    --color,
    linear-gradient(91deg, #35c5ee 19.72%, #1e7188 79.08%)
  ) !important;
}

.prompt {
  display: flex;
  padding: 25px 23px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #447c45;
  background: #dff0d8;
  margin-top: 24px;
}

.prompt-msg {
  color: #447c45;
}

@media (max-width: 768px) {
  .contact-us-form {
    flex-direction: column;
  }
  .input-panel {
    width: 100%;
  }
  .contact-panel {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .contact-us-form {
    gap: 15px;
  }
  .prompt {
    padding: 15px;
  }
}
