.melodi-works {
  text-align: -webkit-center;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 58px;
  align-items: start;
  width: 100%;
}
.melodi-target img {
  height: 686px;
}

@media (max-width: 480px) {
  .features-grid {
    grid-template-columns: repeat(1, 1fr);
    gap: 58px;
  }
}
