.cards-head{
    display: flex;
    align-items: start;
    flex-direction: column;
    gap : 60px;
}
.flex-container-title{
    text-align: left;
}
.cards{
    display: flex;
    gap: 28px;
    text-align: left;
    flex-wrap: wrap;
}
.card{
    border-radius: 10px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    cursor: pointer;
    padding: 20px 30px;
    flex: 1 1 calc(25% - 28px); 
    border: 1px solid #DDE1E6;
    max-width: calc(25% - 28px);
}

.number{
    font-size: 24px;
    color:#B3B3B3;
    line-height:31.2px;
    font-weight: 600;
  }
  .heading{
    font-size: 20px;
    color:#0B0B0B;
    line-height:28px;
    font-weight: 500;
  }


@media (max-width: 768px) {
    .card {
        flex: 1 1 calc(50% - 28px); /* 2 cards per row on smaller screens */
        max-width: calc(50% - 28px);
    }
}

@media (max-width: 480px) {
    .card {
        flex: 1 1 100%; /* 1 card per row on very small screens */
        max-width: 100%;
    }
}