*,*::before,*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Chakra Petch";
  /* padding: 34px 68px 0px 68px; */
  text-align: -webkit-center;
  background-color: #fefefe;
}

/* Reset styles for all links */
a {
  text-decoration: none;
  color: inherit;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.logo{
  width: 167px;
  height: 40px;
}
.container-pd {
  padding-left: 68px;
  padding-right: 68px;
}
.container {
  padding:80px 68px;
}

/* .container-mt {
  margin-top: 112px;
} */

/* .container-mw {
  max-width: 1440px;
} */

.text-gradient {
  background: var(
    --color,
    linear-gradient(91deg, #35c5ee 19.72%, #1e7188 79.08%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.logo-text {
  font-family: Orbitron;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.header-text {
  color: var(--Black, var(--color-black, #0b0b0b));

  /* Header text */
  font-family: "Chakra Petch";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.capsules-text,
.button-text,
.heading-tag {
  font-family: "Chakra Petch";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading1 {
  color: var(--color-black, #0b0b0b);
  text-align: center;
  margin-top: 10px;

  /* Heading 1 */
  font-family: Orbitron;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading2 {
  color: var(--Black, var(--color-black, #0b0b0b));
  font-family: Orbitron;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.paragraph1 {
  color: var(--color-black, #000000);
  font-family: "Chakra Petch";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-wrap;
}

.form-head1 {
  color: var(--Black, var(--color-black, #0b0b0b));

  /* Form head 1 */
  font-family: "Chakra Petch";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.desktop {
  display: flex !important;
}
.mobile {
  display: none !important;
}

.reset-button {
  all: unset;
  display: inline-block;
  cursor: pointer;
  max-width: fit-content;
  border-radius: 32px;
}
@media (max-width: 1200px) {
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  /* body {
    padding-left: 40px;
    padding-right: 40px;
  } */
  .container {
    padding-left: 40px;
    padding-right: 40px;
  }

  .container-mt {
    margin-top: 80px;
  }

  .container-pd {
    padding-left: 40px;
    padding-right: 40px;
  }
  .logo-text {
    font-size: 28px;
  }

  .header-text {
    font-size: 18px;
  }

  .capsules-text,
  .button-text,
  .heading-tag {
    font-size: 12px;
  }

  .heading1 {
    font-size: 42px;
  }
  .heading2 {
    font-size: 24px;
  }

  .paragraph1 {
    font-size: 14px;
  }
  .form-head1 {
    font-size: 16px;
  }
}

/* @media (max-width: 786px) {
  .container {
    text-align: center;
  }
} */

@media (max-width: 480px) {
  /* body {
    padding-left: 25px;
    padding-right: 25px;
  } */
  .container {
    margin-top: 0;
    padding: 25px;
  }
  .container-pd {
    padding-left: 25px;
    padding-right: 25px;
  }
  .container-mt {
    margin-top: 60px;
  }

  .logo-text {
    font-size: 24px;
  }

  .header-text {
    font-size: 16px;
  }

  .capsules-text,
  .button-text,
  .heading-tag {
    font-size: 12px;
  }

  .heading1 {
    font-size: 36px;
  }
  .heading2 {
    font-size: 20px;
  }

  .paragraph1 {
    font-size: 12px;
  }
  .form-head1 {
    font-size: 14px;
  }
}
