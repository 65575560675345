.spanfi-features .banner-img img {
  object-fit: contain;
  height: unset;
}

.about-spanfi .banner-img img {
  object-fit: contain;
  max-height: 400px;
  height: auto;
}

.tech-desc {
  display: flex;
  text-align: left;
  gap: 35px;
  margin-top: 20px;
}

@media (max-width: 480px){
    .tech-desc{
        flex-direction: column;
    }
}
