.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 195px;
}

.rotating-earth > video {
  max-width: 846px;
  width: 100%;
  height: 778px;
  object-fit: cover;
}

.hero-about {
  height: 736px;
  position: relative;
  margin-top: -27%;
  width: 100%;
  max-width: 2150px;
}

.hero-about img {
  width: 100%;
}
.about-overlay-text {
  position: absolute;
  top: 0;
  align-items: center;
  width: 100%;
  margin-top: 17%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  text-align: center;
}

.about-overlay-text > div {
  color: white;
  max-width: 853px;
}
.faq {
  text-align: -webkit-center;
}
.faq-ques {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 68px;
  max-width: 1003px;
}

@media (max-width: 1540px) {
  .hero-about {
    margin-top: -559px;
  }
  .about-overlay-text {
    margin-top: 222px;
  }
  .hero {
    padding-bottom: unset;
  }
}

@media (max-width: 1200px) {
  .rotating-earth > video {
    width: 546px;
    height: 400px;
    display: none;
  }

  .about-bg {
    display: none;
  }

  .hero-about {
    height: auto;
    margin-top: auto;
  }
  .about-overlay-text {
    margin-top: auto;
    position: relative;
  }

  .about-overlay-text > div {
    color: black;
    text-align: -webkit-center;
  }

  .hero {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .hero {
    max-width: 500px;
  }
}

@media (max-width: 480px) {
  .hero {
    max-width: 350px;
    margin-top: 36px;
  }
}
